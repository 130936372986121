
    import Vue from "vue";
    import Component from "vue-class-component";
    import MultiSelectList from "@/components/MultiSelectList.vue"
    import store from "@/store/store";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { ILookupItem } from "@/model/LookupItem";
    import { Supplier as ModelSupplier } from "@/model/Supplier";
    import { Watch } from "vue-property-decorator";
    import * as toastr from "toastr";
    import eventBus from "@/stuff/EventBus";
import { BuyerSupplier, IBuyerSupplier } from "@/model/BuyerSupplier";
import { BuyerSupplierSearchParameters } from "@/model/BuyerSupplierSearchParameters";
import { TriState } from "@/model/Enums";

    @Component({ components: { MultiSelectList }})
    export default class Details extends Vue {

        async mounted() {
            await Promise.all([
                store.dispatch("loadCompanyTypeList"),
                store.dispatch("loadCountryList"),
                store.dispatch("loadTradeList"),
                store.dispatch("loadTradingAreaList"),
                this.loadBuyerList()
            ])

            this.supplierID = +this.$store.state.signedInUser.supplierID;
            await Promise.all([
                this.load(this.supplierID),
                this.loadBuyerSuppliers()
            ]);
        }

        private isLoading: boolean = true;

        private supplierID: number = 0;
        private readonly supplier = new ModelSupplier();
        readonly buyerSupplierList: Array<BuyerSupplier> = [];

        private get companyTypeList(): Array<ILookupItem> { return this.$store.state.companyTypeList; }
        private get companyTypeOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.companyTypeList); }

        private get countryList(): Array<ILookupItem> { return this.$store.state.countryList; }
        private get countryOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.countryList); }

        private get tradingAreaList(): Array<ILookupItem> { return this.$store.state.tradingAreaList; }
        private get tradingAreaOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.tradingAreaList); }
        
        private buyerList: Array<ILookupItem> = [];

        private get title(): string {
            return this.supplier.id ? this.supplier.name : "Supplier";
        }

        private get companyTypeText(): string {
            return utils.lookupDescription(this.supplier.companyTypeID, this.companyTypeList);
        }

        private get countryText(): string {
            return utils.lookupDescription(this.supplier.countryID, this.countryList);
        }

        @Watch("supplier.tradingAreaIDs")
        private async onTradingAreasChanged() {
            if (!this.isLoading) await this.saveTradingAreas();
        }

        async loadBuyerList() {
            this.buyerList = await apiClient.get(`/api/buyer/lookups?supplierID=${this.supplierID}`);
        }

        async load(supplierID: number) {
            this.isLoading = true;

            if (supplierID > 0) {
                const serverSupplierData = await apiClient.get(`api/supplier/Load?id=${supplierID}`);
                utils.resetObject(this.supplier);
                // nb - update maps suppliers and contacts too
                this.supplier.update(serverSupplierData);
            }
            else {
                utils.resetObject(this.supplier);
            }

            this.isLoading = false;
        }

        async loadBuyerSuppliers() {
            this.buyerSupplierList.length = 0;

            if (utils.isEmptyId(this.supplierID)) {
                return;
            }

            const parameters = new BuyerSupplierSearchParameters();
            parameters.supplierID = this.supplierID;
            parameters.isDeleted = TriState.False;
            const responseData = await apiClient.post("api/buyerSupplier/search", parameters);
            this.buyerSupplierList.push(...responseData.list.map((b: IBuyerSupplier) => new BuyerSupplier(b)));
        }

        private async saveTradingAreas() {
            const response: { message: string } =  await apiClient.post("/api/supplier/saveTradeAreas", this.supplier, event);
            if (response.message != "ok") toastr.error("Failed to save trade areas")
            eventBus.$emit("supplier-trading-areas-updated");
        }
    }
